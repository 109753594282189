<template>
  <div class="max-w-screen-xl mx-auto">
    <div class="py-12 bg-gray-100">
      <h2 class="text-3xl font-bold text-center">Upcoming Races</h2>
      <div class="flex flex-wrap justify-center gap-4 mt-6">
        <div class="max-w-xs w-full shadow-lg rounded-lg overflow-hidden" v-for="race in races.races" :key="race">
          <div v-if="determineActiveRace(race['Date'])" class="p-4 bg-red-100">
            <h3 class="text-xl font-bold">{{ race['Grand Prix'] }} Grand Prix: Round-{{ race['Round'] }}</h3>
            <p class="mt-1">{{ race['Date'] }}</p>
            <p class="mt-1">{{ race['Circuit'] }}, {{ race['Country'] }}</p>
          </div>
          <div v-else class="p-4 bg-grey">
            <h3 class="text-xl font-bold">{{ race['Grand Prix'] }} Grand Prix: Round-{{ race['Round'] }}</h3>
            <p class="mt-1">{{ race['Date'] }}</p>
            <p class="mt-1">{{ race['Circuit'] }}, {{ race['Country'] }}</p>
          </div>
        </div>
        <!-- Repeat for other races -->
      </div>
    </div>

    <!-- News Section -->
    <div class="py-12">
      <h2 class="text-3xl font-bold text-center">Latest News</h2>
      <div class="flex flex-wrap justify-center gap-4 mt-6">
        <!-- Mock news item -->
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg overflow-hidden">
          <div class="p-4">
            <h3 class="text-xl font-bold">F1 2024 Season Preview</h3>
            <p class="mt-2 text-gray-600">Get ready for an exciting season with new drivers, cars, and regulations.</p>
          </div>
        </div>
        <!-- Repeat for other news items -->
      </div>
    </div>
  </div>
</template>


<script>
import raceData from '../assets/data/races.json'

export default {
  name: 'HomeComponent',
  props: {
    msg: String
  },
  data() {
    return {
      races: raceData,
      isActiveRace: false,
    }
  },
  mounted() { },
  computed: {
    shouldShowRed() {
      return this.isActiveRace;
    }
  },
  methods: {
    determineActiveRace(raceDate) {
      const today = new Date();

      return new Date(raceDate) >= today;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
