<template>
  <header class="bg-f1-header bg-cover bg-center h-96 text-white flex flex-col justify-center">
    <div class="container mx-auto px-6">
      <h1 class="text-5xl font-bold">Welcome to Unofficial F1 World</h1>
      <p class="mt-4">The ultimate source for Formula 1 news, insights, and updates.</p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>
<style scoped>
</style>
