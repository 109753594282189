<template>
  <div class="font-sans flex flex-col min-h-screen">
    <NavBar />
    <HeaderComponent />
     <!-- Main content area should grow to push the footer down -->
     <main class="flex-grow">
      <HomeComponent msg="Welcome to iFormula1.com" />
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import HomeComponent from './components/HomeComponent.vue'
import NavBar from './components/NavBar.vue'
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
  }
}
</script>

<style scoped></style>
