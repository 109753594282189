<!-- Navbar.vue -->
<template>
    <nav class="sticky top-0 z-50 bg-red-800 text-white shadow-lg">
      <div class="container mx-auto px-6 py-3 flex justify-between items-center">
         <!-- Placeholder for the logo -->
      <div class="logo-placeholder flex items-center">
        <!-- Temporary text or image to represent the logo -->
        <!-- <div class="h-10 flex justify-center items-center">
          <img src="../assets/logos/logo-2.webp" alt="iFormula1 Logo" class="h-8">
        </div> -->
        <span class="text-xl font-bold mr-2">iFormula1.com</span>
        <!-- Alternatively, you can use a temporary image -->
        <!-- Placeholder styling -->
       
      </div>
        <ul class="flex items-center space-x-4">
          <li><a href="#" class="hover:text-red-300">Home</a></li>
          <li><a href="#" class="hover:text-red-300">News</a></li>
          <li><a href="#" class="hover:text-red-300">Schedule</a></li>
          <li><a href="#" class="hover:text-red-300">Teams</a></li>
          <li><a href="#" class="hover:text-red-300">Drivers</a></li>
        </ul>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavBar',
  }
  </script>
  